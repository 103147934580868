
import {
  Component, Inject, Prop, ProvideReactive, Vue,
} from 'nuxt-property-decorator'

@Component({
  name: 'Infobox',
  components: {
    BaseHeadline: () => import('./base/BaseHeadline.vue'),
    Triangle: () => import('./svgs/Triangle.vue'),
  },
})
export default class Infobox extends Vue {
  @ProvideReactive('linkColor') linkColor : 'white' | 'darkgreen' = 'darkgreen'

  @Prop({ default: '' }) headline! : string

  @Prop({ default: true }) showIcon! : boolean

  @Prop({ default: true }) coloredBackground! : boolean

  @Inject({ from: 'infoboxUsedInContentProjection', default: false }) usedInContentProjection! : boolean

  async created () {
    this.linkColor = this.coloredBackground ? 'white' : 'darkgreen'
  }
}
